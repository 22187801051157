<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('analytics')
export default {
  computed: {
    ...mapState(['metrics']),
    ...mapState(['loadingMetrics']),
    ...mapState(['company']),
    ...mapState(['location']),
    ...mapState(['dateRange']),
    ...mapState(['source']),
    ...mapState(['demoMode']),
  },
  methods: {
    ...mapActions(['updateMetrics']),
    ...mapActions(['setLocation']),
    ...mapActions(['setDateRange']),
    ...mapActions(['setSource']),
  },
}
</script>
