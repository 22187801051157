<template>
  <KPICard :title="'Loyalty Members'">
    <div class="row align-items-center">
      <div class="col">
        <h6 class="card-title text-uppercase text-muted mb-2">Loyalty Members</h6>
        <span class="h2 mb-0">{{ totalLoyaltyMembers }}</span>
      </div>
      <div class="col-auto">
        <span class="h2 fe fe-users text-muted mb-0"></span>
      </div>
    </div>
  </KPICard>
</template>

<script>
import KPICard from '@/components/Cards/KPICard'

export default {
  name: 'TotalLoyaltyMembersCard',
  props: ['totalLoyaltyMembers'],
  components: {
    KPICard,
  },
}
</script>

<style scoped lang="scss"></style>
