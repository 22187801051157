<template>
  <div class="row">
    <div class="col-12 col-lg-6 col-xl">
      <TotalCheckinsCard :totalCheckins="metrics.totalCheckins" />
    </div>
    <div class="col-12 col-lg-6 col-xl">
      <TotalLoyaltyMembersCard :totalLoyaltyMembers="metrics.totalLoyaltyMembers" />
    </div>
    <div class="col-12 col-lg-6 col-xl">
      <TotalPointsEarnedCard :totalPointsEarned="metrics.totalPointsEarned" />
    </div>
    <div class="col-12 col-lg-6 col-xl">
      <TotalPointsSpentCard :totalPointsSpent="metrics.totalPointsSpent" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'
import TotalCheckinsCard from '@/components/Modules/Analytics/Metric/TotalCheckinsCard'
import TotalLoyaltyMembersCard from '@/components/Modules/Analytics/Metric/TotalLoyaltyMembersCard'
import TotalPointsEarnedCard from '@/components/Modules/Analytics/Metric/TotalPointsEarnedCard'
import TotalPointsSpentCard from '@/components/Modules/Analytics/Metric/TotalPointsSpentCard'

export default {
  name: 'ReviewOverralStats',
  extends: AnalyticsBase,
  components: {
    TotalCheckinsCard,
    TotalLoyaltyMembersCard,
    TotalPointsEarnedCard,
    TotalPointsSpentCard,
  },
  computed: {
    ...mapState('company', ['activeCompany']),
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        await this.updateMetrics([
          'totalCheckins',
          'totalLoyaltyMembers',
          'totalPointsEarned',
          'totalPointsSpent',
        ])
      },
    },
  },
}
</script>
