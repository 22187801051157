<template>
  <div class="d-flex align-items-start flex-wrap">
    <!-- date -->
    <div class="dropdown d-inline-block mr-4 mb-2 float-left">
      <h6 class="header-pretitle mt-3">Date Range</h6>
      <DatePicker
        @update="dateUpdate"
        :minDate="selectOldestReviewTimestamp"
        :key="selectOldestCompanyStartDate([activeCompany])"
      />
    </div>

    <!-- location -->
    <div class="dropdown d-inline-block float-left mr-4 mb-2" v-if="showLocationFilter">
      <h6 class="header-pretitle mt-3">Location</h6>
      <GroupingFilter
        :nonGroupMembers="selectActiveLocations"
        nonGroupMemberLabel="Locations"
        memberType="location"
        @update="groupingUpdate"
        :init="{}"
        :key="activeCompany._id"
      />
    </div>

    <!-- platform -->
    <div class="dropdown d-inline-block float-left mr-4 mb-2" v-if="showPlatformFilter">
      <h6 class="header-pretitle mt-3">Platform</h6>
      <SingleSelectFilter
        :options="selectAllLocationReviewNetworks"
        @updatedSelection="networkUpdate"
        :placeholder="'Select Network'"
      />
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import SingleSelectFilter from '@/components/Modules/Filters/SingleSelectFilter'
import DatePicker from '@/components/Modules/Filters/DatePicker'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const CompanyModule = createNamespacedHelpers('company')
const DashboardModule = createNamespacedHelpers('dashboard')
const ReviewModule = createNamespacedHelpers('review')

export default {
  name: 'ReviewFilters',
  components: {
    GroupingFilter,
    DatePicker,
    SingleSelectFilter,
  },
  data: () => ({
    filteredDate: null,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: [],
    filteredNetwork: null,
  }),
  computed: {
    ...LocationModule.mapGetters(['selectActiveLocations', 'selectAllLocationReviewNetworks']),
    ...CompanyModule.mapGetters(['activeCompany', 'selectOldestCompanyStartDate']),
    ...ReviewModule.mapGetters(['selectOldestReviewTimestamp']),
    showLocationFilter() {
      return this.selectActiveLocations?.length > 1
    },
    showPlatformFilter() {
      return this.selectAllLocationReviewNetworks?.length > 1
    },
  },
  methods: {
    ...ReviewModule.mapActions(['updateFilters']),
    ...DashboardModule.mapActions(['applyFilters']),
    dateUpdate(val) {
      this.filteredDate = val
      this.formatAndApplyFilters()
    },
    groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.formatAndApplyFilters()
    },
    networkUpdate(val) {
      this.filteredNetwork = val
      this.formatAndApplyFilters()
    },
    async formatAndApplyFilters() {
      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      const filters = {
        companies: [this.activeCompany._id],
        dateRange:
          this.filteredDate && Object.keys(this.filteredDate).length ? this.filteredDate : null,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
        network: this.filteredNetwork,
      }
      this.applyFilters(filters)
      this.updateFilters(filters)
    },
  },
}
</script>
