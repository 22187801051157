<template>
  <div :key="selectRefreshKey">
    <div class="row mb-3 mx--4 px-2">
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="15" />
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="16" />
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="12" />
      </div>
    </div>

    <!-- row 2 start -->
    <div class="row mb-3 mx--4 px-2">
      <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <DashboardWidget :widgetId="18" />
      </div>
      <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
        <DashboardWidget :widgetId="17" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardWidget from '@/components/Modules/Dashboard/Widget/DashboardWidget'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'ReviewMetrics',
  components: {
    DashboardWidget,
  },
  data: () => ({}),
  computed: {
    ...DashboardModule.mapGetters(['selectWidgetIds', 'selectRefreshKey']),
  },
}
</script>