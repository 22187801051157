<template>
  <div class="card" style="height: 415px">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-header-title">Loyalty</h4>
        </div>
        <div v-if="linkToLoyalty" class="col-auto">
          <router-link class="small" :to="{ name: 'loyalty' }">View all</router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-for="(platform, index) in metrics.reviewSummary" :key="platform.source">
        <div class="row align-items-center">
          <div class="col-auto">
            <a class="avatar" href="#!">
              <img
                alt="..."
                class="avatar-img rounded"
                :src="'/theme/img/platforms/' + platform.source + '.svg'"
              />
            </a>
          </div>
          <div class="col ml--2">
            <!-- Title -->
            <h4 class="card-title mb-1">
              <div v-if="!isNaN(platform.rating)">
                <div class="stars">
                  <!-- <StarRating :rating="platform.rating"/> -->
                </div>
                <a href="#!">{{ platform.rating }}</a>
              </div>
              <span class="ml--1 text-muted" v-if="isNaN(platform.rating)">No rating yet.</span>
            </h4>
            <p class="card-text small text-muted">{{ platform.count }} reviews</p>
          </div>
        </div>
        <!-- / .row -->
        <!-- Divider -->
        <hr v-if="index != metrics.reviewSummary.length - 1" />
      </div>
    </div>
    <!-- / .card-body -->
  </div>
</template>

<script>
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'

export default {
  name: 'TopLoyaltyRewardsCard',
  extends: AnalyticsBase,
  props: {
    linkToLoyalty: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      summary: [],
    }
  },
  components: {},
  mounted() {
    this.updateMetrics(['reviewSummary'])
  },
}
</script>

<style scoped lang="scss">
.card-header {
  flex-grow: 0;
}
</style>
