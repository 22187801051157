<template>
  <BasicLineChart
    :chart-data="chartData"
    :grid-lines="gridLines"
    :css-classes="'chart'"
  ></BasicLineChart>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BasicLineChart from '@/components/Charts/BasicLineChart'
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'

export default {
  name: 'LoyaltySummaryChartContainer',
  extends: AnalyticsBase,
  components: {
    BasicLineChart,
  },
  data() {
    return {
      gridLines: {
        beginAtZero: false,
      },
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.metrics.checkinsChart.labels,
        datasets: [
          {
            label: 'Checkins',
            data: this.metrics.checkinsChart.data,
          },
        ],
      }
    },
    ...mapState('company', ['activeCompany']),
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        await this.updateMetrics(['checkinsChart'])
      },
    },
  },
}
</script>

<style scoped lang="scss"></style>
