<template>
  <div class="container-fluid">
    <ReviewHeader />
    <div v-if="initialized">
      <ReviewFilters />
      <ReviewMetrics class="mt-4" />
      <ReviewList class="mt-4" :key="selectRefreshKey" :fetchMorePending="fetchMorePending" />
    </div>
    <div v-else class="text-center">
      <b-spinner variant="primary" />
    </div>
  </div>
</template>

<script>
import ReviewHeader from './ReviewHeader.vue'
import ReviewFilters from './ReviewFilters.vue'
import ReviewMetrics from './ReviewMetrics.vue'
import ReviewList from './ReviewList.vue'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')
const LocationModule = createNamespacedHelpers('location')
const ReviewModule = createNamespacedHelpers('review')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'ReviewView',
  components: {
    ReviewHeader,
    ReviewFilters,
    ReviewMetrics,
    ReviewList,
  },
  data: () => ({
    initialized: false,
    fetchMorePending: false,
  }),
  async mounted() {
    await Promise.all([
      this.initializeFilters(),
      this.getUserLocationsByActiveCompany(),
      this.fetchGroups({
        memberType: 'location',
        companyIds: this.selectActiveCompanyIds,
      }),
    ])
    window.addEventListener('scroll', this.handleScroll)
    this.initialized = true
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...ReviewModule.mapGetters(['selectRefreshKey', 'selectAllReviewsLoaded']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  methods: {
    ...DashboardModule.mapActions(['initializeFilters']),
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...ReviewModule.mapActions(['fetchReviewList']),
    ...GroupModule.mapActions(['fetchGroups']),
    async handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
        if (!this.fetchMorePending && !this.selectAllReviewsLoaded) {
          this.fetchMorePending = true
          await this.fetchReviewList()
          this.fetchMorePending = false
        }
      }
    },
  },
}
</script>
