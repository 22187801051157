<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title ml-3">Reviews</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewHeader',
}
</script>