<template>
  <Multiselect
    :show-labels="false"
    closeOnSelect
    v-model="selected"
    @input="updatedSelection"
    :options="options"
  >
    <template slot="placeholder">
      <span class="text-secondary">
        {{ placeholder || 'Select Option' }}
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span class="text-capitalize">{{ props.option }}</span>
      </div>
    </template>
    <template slot="singleLabel">
      <div>
        <span class="text-capitalize">{{ selected }}</span>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleSelectFilter',
  props: {
    placeholder: {
      type: String,
      default: 'Select option',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    selected: null,
  }),
  methods: {
    updatedSelection(selection) {
      this.$emit('updatedSelection', selection)
    },
  },
}
</script>
