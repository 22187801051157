<template>
  <MainContent>
    <div v-if="activeCompany" :key="activeCompany._id">
      <ReviewView />
    </div>
  </MainContent>
</template>

<script>
import ReviewView from '@/components/Modules/Review/ReviewView'
import MainContent from '@/components/MainContent/MainContent'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'ReviewsPage',
  components: {
    MainContent,
    ReviewView,
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany']),
  },
  mounted() {
    trackEvent(this.$intercom, 'Reviews')
  },
}
</script>
