<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <!-- Title -->
          <h4 class="card-header-title">Checkins</h4>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <div class="card-body">
      <!-- Chart -->
      <LoyaltySummaryChartContainer :loyaltySummaryChart="metrics.loyaltySummaryChart" />
    </div>
  </div>
</template>

<script>
import LoyaltySummaryChartContainer from './LoyaltySummaryChartContainer'
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'

export default {
  name: 'LoyaltySummaryChartCard',
  extends: AnalyticsBase,
  components: {
    LoyaltySummaryChartContainer,
  },
}
</script>

<style scoped lang="scss"></style>
