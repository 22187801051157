<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'BasicLineChart',
  extends: Line,
  props: ['gridLines', 'chartData'],
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        layout: {
          padding: {
            top: 1.5, // half border width
          },
        },
        scales: {
          yAxes: [
            {
              ...(this.gridLines && { gridLines: this.gridLines }),
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    return value
                  }
                },
                beginAtZero: false,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || ''
              var yLabel = item.yLabel
              var content = ''

              if (data.datasets.length > 1) {
                content +=
                  // '<span class="popover-body-label mr-auto">' +
                  label
                // + '</span>'
              }

              content +=
                // '<span class="popover-body-value">' +
                yLabel
              // + '</span>'
              return content
            },
          },
        },
      },
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>

<style scoped lang="scss"></style>
