<template>
  <div class="card" ref="card" v-b-popover.hover.top="tooltip" :title="title">
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/*eslint-env jquery*/

export default {
  name: 'KPICard',
  props: ['title', 'tooltip'],
}
</script>

<style scoped lang="scss"></style>
