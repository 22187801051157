<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4">
          <img
            src="/theme/img/illustrations/scale.svg"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 272px"
          />

          <h2>We're creating your report.</h2>

          <p class="text-muted">
            We're working on pulling in all of your loyal customers and analyzing their rewards.
          </p>

          <p class="text-primary">Thanks for your patience.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoyaltyOffstate',
}
</script>

<style scoped lang="scss"></style>
