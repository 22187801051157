<template>
  <div :class="[filtersClass]">
    <div v-if="!excludeDateFilter" class="dropdown inline-block float-left mb-1">
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-outline-primary"
        :class="[btnSize, btnClass]"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        type="button"
      >
        <span class="fe fe-clock"></span> {{ dateRange.title }}
      </button>
      <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
        <li v-for="option of dateRangeOptions" :key="option.title">
          <a class="dropdown-item" href="javascript:;" v-on:click="setDateRange(option)">{{
            option.title
          }}</a>
        </li>
        <!-- TODO: <li>
          <a
            class="dropdown-item"
            href="#"
          >Pick a date range...</a>
        </li> -->
      </ul>
    </div>
    <div v-if="includeLocationFilter" class="dropdown inline-block float-left ml-3">
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-outline-primary"
        :class="[btnSize, btnClass]"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        type="button"
      >
        <span class="fe fe-map-pin"></span>
        {{ location ? location.friendlyName || location.city : 'All' }}
      </button>
      <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="javascript:;" v-on:click="setLocation(undefined)">All</a>
        </li>
        <li v-for="option of userActiveCompanyLocations" :key="option._id">
          <a class="dropdown-item" href="#" v-on:click="setLocation(option)"
            >{{ option.friendlyName || option.city }}
            <br />
            <small class="text-muted">{{
              `${option.addressDetails.streetNumber} ${option.addressDetails.streetName}`
                | truncate(20)
            }}</small>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex'
const userState = createNamespacedHelpers('user').mapState
const companyState = createNamespacedHelpers('company').mapState
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'

export default {
  name: 'CompanyFilters',
  extends: AnalyticsBase,
  props: ['filtersClass', 'btnSize', 'btnClass', 'includeLocationFilter', 'excludeDateFilter'],
  data() {
    return {
      dateRangeOptions: [
        {
          title: 'Last 30 days',
          start: this.$moment().subtract(30, 'd'),
          end: new Date(),
        },
        {
          title: 'Last 60 days',
          start: this.$moment().subtract(60, 'd'),
          end: new Date(),
        },
        {
          title: 'Last 90 days',
          start: this.$moment().subtract(90, 'd'),
          end: new Date(),
        },
        {
          title: 'Last year',
          start: this.$moment().subtract(365, 'd'),
          end: new Date(),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('location', ['userActiveCompanyLocations']),
    ...companyState(['activeCompany']),
    ...userState(['onlineUser']),
  },
  methods: {
    ...mapActions('location', ['getUserLocationsByActiveCompany']),
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        if (!this.dateRange.title && !this.excludeDateFilter) {
          this.setDateRange(this.dateRangeOptions[2]) // 90 days
        }

        await this.getUserLocationsByActiveCompany()
      },
    },
  },
}
</script>
