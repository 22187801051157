<template>
  <MainContent>
    <div class="header">
      <div class="container">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title">Loyalty</h1>
            </div>
            <div class="col-auto">
              <CompanyFilters include-location-filter="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="metrics.totalCheckins == 0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <LoyaltyOffstate />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <LoyaltyOverallStats />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <LoyaltySummaryChartCard />
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-lg-4">
            <TopLoyaltyRewardsCard />
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-12">
            <ReviewsList />
          </div>
        </div> -->
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CompanyFilters from '@/components/Modules/Company/CompanyFilters'
import LoyaltySummaryChartCard from '@/components/Modules/Loyalty/HeroSummary/LoyaltySummaryChartCard'
import LoyaltyOverallStats from '@/components/Modules/Loyalty/KPI/LoyaltyOverallStats'
import TopLoyaltyRewardsCard from '@/components/Modules/Loyalty/TopLoyaltyRewardsCard'
// import LoyaltyList from '@/components/Modules/Loyalty/LoyaltyList'
import LoyaltyOffstate from '@/components/Modules/Loyalty/LoyaltyOffstate'
import AnalyticsBase from '@/components/Modules/Analytics/AnalyticsBase'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'LoyaltyView',
  extends: AnalyticsBase,
  components: {
    MainContent,
    CompanyFilters,
    LoyaltySummaryChartCard,
    LoyaltyOverallStats,
    TopLoyaltyRewardsCard,
    // LoyaltyList,
    LoyaltyOffstate,
  },
  mounted() {
    trackEvent(this.$intercom, 'Loyalty')
    this.updateMetrics(['totalCheckins'])
  },
}
</script>

<style scoped lang="scss"></style>
