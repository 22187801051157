<template>
  <div id="review-list">
    <div v-if="!selectReviewListLoading">
      <div v-if="selectReviews.length">
        <div class="d-flex flex-wrap">
          <div
            id="review-list-wrapper"
            class="card p-4 mb-3 reviewList"
            v-for="review in selectReviews"
            :key="review._id"
          >
            <ReviewCard :review="review" />
          </div>
        </div>

        <!-- pagination states -->
        <div v-if="fetchMorePending" class="d-flex justify-content-center align-items-center my-3">
          <b-spinner small variant="primary" />
        </div>
        <div
          v-if="selectAllReviewsLoaded"
          class="d-flex justify-content-center align-items-center my-3"
        >
          No more reviews
        </div>
      </div>

      <!-- empty state -->
      <div v-else>- No reviews matching given filters</div>
    </div>

    <!-- loader -->
    <div class="d-flex flex-wrap" v-else>
      <div id="review-list-loader" class="mb-3 reviewList" v-for="x in 8" :key="'skeleton-' + x">
        <b-skeleton height="200px" />
      </div>
    </div>
  </div>
</template>

<script>
import ReviewCard from './ReviewCard.vue'
import { createNamespacedHelpers } from 'vuex'

const ReviewModule = createNamespacedHelpers('review')

export default {
  name: 'ReviewList',
  components: {
    ReviewCard,
  },
  props: {
    fetchMorePending: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    intersectionObserver: null,
  }),
  async mounted() {
    await this.fetchReviewList()
  },
  computed: {
    ...ReviewModule.mapGetters([
      'selectReviews',
      'selectReviewListLoading',
      'selectAllReviewsLoaded',
    ]),
  },
  methods: {
    ...ReviewModule.mapActions(['fetchReviewList']),
  },
}
</script>

<style lang="scss" scoped>
#review-list-wrapper:nth-child(even) {
  margin-left: 12px !important;
}
#review-list-loader:nth-child(even) {
  margin-left: 12px !important;
}
.virtual {
  position: absolute;
  height: 200px;
  top: -200px;
  width: 100%;
}
.reviewList {
  flex: 100%;
}
@media (min-width: 768px) {
  .reviewList {
    flex: 48%;
  }
}
</style>
